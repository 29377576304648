import React from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import clsx from 'clsx';
import { makeStyles, List, Divider, ListItem } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Text from '../common/text.component';
import Link from '../common/Link.component';
import { goToCuenta, goToIngresar } from '../../api/navigation';
import { logout } from '../../api/authorization';
import { LOGOUT } from '../../mutations/logout';
import HiddenOn from '../common/HiddenOn.component';
import { useAppContext } from '../../context/AppContext';
import setUserRoute from '../../context/user/actions/setUserRoute';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import { DesktopUserMenu } from './UserMenu';
import MobileDrawer from './MobileDrawer.component';
import { capitalizeEachWord } from '../../utils/typography.utils';
import { navigationRoutes } from '../../api/navigationRoutes.enum';

const LOGIN_TEXT = 'Ingresar';
const LOGOUT_TEXT = 'Cerrar sesión';
const drawerWidth = 20;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  margin: {
    marginLeft: '1rem',
  },
  loginButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *': {
      marginTop: '0.125rem',
      fontWeight: 'normal',
    },
  },
  boxTxt:{
    margin:'1rem 0 1rem 1rem'
  },
  registerButton: {
    width: '12rem',
  },
  welcomeMobileHeader: {
    display: 'block',
    fontSize: '1rem',
  },
  welcomeMobileText: {
    display: 'block',
    fontSize: '1.25rem',
    fontWeight: '600',
  },
  regular: {
    marginRight: '0.313rem',
    fontSize: '0.875rem',
  },
  welcomeMessage: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'end',
    },
  },
  welcomeDesktop: {
    paddingLeft: '1.625rem',
    borderLeft: `1px solid ${theme.palette.grayScale.g200}`,
    height: '2.3rem',
  },
  fullNameDesktopContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  drawerPaper: {
    width: `${drawerWidth}rem`,
    backgroundColor: theme.palette.grayScale.g100,
    color: theme.palette.secondary.main,
    marginTop: '3.5rem',
  },
  listOption:{
    fontSize:'1rem'
  },
  divider:{
    backgroundColor:`${theme.palette.tertiary.background}`,
    height:'0.031rem',
    margin:'0.625rem 0.625rem',
  },
  lineSeparator: {
    marginLeft: '1rem',
    marginRight: '1rem',
  },
  lineSeparatorSection:{
    marginLeft: '1rem',
    marginRight: '1rem',
    marginBottom:'0.5rem',
  },
  '& .MuiListItem-button:hover': {
    textDecoration: 'none',
    backgroundColor: 'none',
    color:`${theme.palette.primary.main}`
  },
  mobileLoginSection: {
    color: theme.palette.secondary.main,
  },
  mobileLoginHeader: {
    marginTop:'2rem',
    background: `${theme.palette.grayScale.g0}`,
    '& .MuiButtonBase-root': {
      height: 'fit-content',
    }
  },
  mobileLoginContent: {
    position: 'relative',
  },
  welcomeTxt:{
    lineHeight:'1.5rem'
  },
  fullNameTxt:{
    lineHeight:'1.875rem'
  },
  menuButton: {
    marginRight: theme.spacing(0),
  },
  logoutSection:{
    marginTop:'1.625rem',
    marginLeft:'1rem'
  },
  linkLogout:{
    fontWeight:'400',
    color: `${theme.palette.secondary.main} !important`,
    fontSize:'1rem'
  }
}));

const LoginSection = ({
  isDesktop = false,
  isLoggedIn = false,
  profile = {},
  loginText = LOGIN_TEXT,
  handleDrawerOpen,
  open,
  onCloseClick,
  isAdmin
}) => {

  const classes = useStyles();

  const WELCOME_TXT = isDesktop ? 'Hola,' : 'Hola!';

  const { firstname, lastname } = profile;
  const firstnameSplit = firstname ? firstname.split(' ') : '';
  const firstnameUpper = firstname !== undefined ? capitalizeEachWord(firstnameSplit[0].toLowerCase()) : '';
  const lasnameUpper = lastname !== undefined ? capitalizeEachWord(lastname.toLowerCase()) : '';
  const fullName = isDesktop ? firstnameUpper : `${firstnameUpper} ${lasnameUpper}`;

  const MENU_OPTIONS = [
    {
      name:{
        desktop:'Mi cuenta',
        mobile:'Datos personales'
      },
      section: 'account',
      id: 0,
      onlyMobile: false
    },
    {
      name:{
        desktop:'',
        mobile:'Datos de la cuenta',
      },
      section: 'account',
      id: 1,
      onlyMobile: true
    },
    {
      name:{
        desktop:'Mis reservas',
        mobile:'Mis reservas',
      },
      section: 'reserve',
      id: 2,
      onlyMobile: false
    },
    {
      name:{
        desktop:'Mis presupuestos',
        mobile:'Mis presupuestos',
      },
      section: 'reserve',
      id: 3,
      onlyMobile: false
    },
  ];

  const client = useApolloClient();

  const [logoutMutation] = useMutation(LOGOUT, {
    onCompleted: () => {
      logout(client);
    },
  });

  function handleLogout(evt) {
    evt.preventDefault();
    logoutMutation();
  }

  const renderAuthText = () => {
    if (!isLoggedIn) {
      return <LoggedOutSection loginText={loginText} />;
    }
    return (
      <LoggedInDesktopSection
        fullName={fullName} onLogout={handleLogout} welcomeTxt={WELCOME_TXT} menuOptions={MENU_OPTIONS}/>
    );
  };

  return (
    <Grid className={clsx(classes.root, { [classes.margin]: isDesktop })}>
      <HiddenOn mobile>{renderAuthText()}</HiddenOn>
      {isLoggedIn && (
        <HiddenOn desktop>
          <LoggedInMobileSection
            className={classes.ingresar}
            onLogout={handleLogout}
            handleDrawerOpen={handleDrawerOpen}
            open={open}
            isLoggedIn={isLoggedIn}
            isAdmin={isAdmin}
            onCloseClick={onCloseClick}
            profile={profile}
            fullName={fullName}
            welcomeTxt={WELCOME_TXT}
            menuOptions={MENU_OPTIONS}
          />
        </HiddenOn>
      )}
    </Grid>
  );
};

const LoggedInDesktopSection = ({ fullName, onLogout, welcomeTxt, menuOptions }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(false);

  const onClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box display="flex" alignItems="center" className={classes.welcomeDesktop}>
      <Box className={classes.fullNameDesktopContainer}>
        <Text light className={classes.regular}>
          {welcomeTxt}
        </Text>
        <Text g500 className={classes.regular}>
          {fullName}
        </Text>
      </Box>
      <IconButton onClick={onClickMenu}>
        <AccountCircleOutlinedIcon />
      </IconButton>
      <DesktopUserMenu
        anchorEl={anchorEl}
        closeMenu={handleClose}
        logout={onLogout}
        menuOptions={menuOptions}
      />
    </Box>
  );
};

const LoggedInMobileSection = ({
  fullName,
  isLoggedIn,
  profile,
  open,
  toggleDrawerOpenUserMenu,
  onLogout,
  welcomeTxt,
  menuOptions,
  onCloseClick
}) => {

  const classes = useStyles();

  const redirectMenuOptions = (id) => {
    onCloseClick(false);
    goToCuenta(id);
  };

  return (
    <Box>
      <MobileDrawer
        onCloseClick={toggleDrawerOpenUserMenu}
        open={open}
        isLoggedIn={isLoggedIn}
        profile={profile}
        anchorPosition={'right'}
      >
        <Box className={classes.mobileLoginHeader}>
          <Box className={classes.mobileLoginSection}>
            <Box className={classes.mobileLoginContent}>
              <List dense={true}>
                <Box className={classes.boxTxt}>
                  <Text small normal light className={classes.welcomeTxt}>
                    {welcomeTxt}
                  </Text>
                  <br />
                  <Text
                    large
                    semibolder
                    primary
                    className={classes.fullNameTxt}
                  >
                    {fullName}
                  </Text>
                </Box>
              </List>
            </Box>
          </Box>
          <Divider/>
        </Box>
        <List component="nav">
          {menuOptions
            .filter((item) => item.section === 'account')
            .map(({ name, id }) => (
              <ListItem
                className={classes.listOption}
                key={id}
                id={id}
                button
                onClick={() => redirectMenuOptions(id)}
              >
                {name.mobile}
              </ListItem>
            ))}
        </List>
        <Divider className={classes.lineSeparatorSection} />
        <List component="nav">
          {menuOptions
            .filter((item) => item.section === 'reserve')
            .map(({ name, id }) => (
              <ListItem
                className={classes.listOption}
                key={id}
                id={id}
                button
                onClick={() => redirectMenuOptions(id)}
              >
                {name.mobile}
              </ListItem>
            ))}
        </List>
        <Divider className={classes.lineSeparator} />
        <Box className={classes.logoutSection}>
          <Link className={classes.linkLogout} onClick={onLogout}>
            {LOGOUT_TEXT}
          </Link>
        </Box>
      </MobileDrawer>
    </Box>
  );
};

export const LoggedOutSection = ({ loginText = LOGIN_TEXT }) => {
  const classes = useStyles();

  const { userDispatch } = useAppContext();

  const handleClick = () => {
    // Guardo la ultima url en el context antes de llevarlo al login
    const { pathname, search } = window.location;
    // Estas son las rutas a skipear (agregar mas al array si hace falta)
    const routes = [
      navigationRoutes.REGISTRO_PARTICULAR.ROOT,
      navigationRoutes.REGISTRO_REVENTA,
      navigationRoutes.INGRESAR,
      navigationRoutes.RECUPERAR,
    ];

    if (!routes.some(e => pathname.includes(e))) {
      setUserRoute(userDispatch, {
        prevRoute: pathname + search,
      });
    }

    goToIngresar(null, pathname);
  };

  return (
    <>
      <Box className={classes.loginButton}>
        <Link onClick={handleClick} className={classes.ingresar}>
          {loginText}
        </Link>
      </Box>
    </>
  );
};

export default LoginSection;
